import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { media } from 'utils/Media'
import SliderIcon from 'svgs/chevron.svg'
import GetImage from 'components/functional/getImage'
import { navigate } from 'gatsby'

const HomeSliderStyles = styled.section`
  width: 100%;
  margin: 1rem auto;
  max-width: 1600px;
  position: relative;
  overflow: hidden;
  .slider {
    width: 90%;
    margin: var(--auto);
    h3 {
      text-align: center;
      font-size: 2rem;
      line-height: 1.25;
      font-family: var(--Poppins-Medium);
    }
    .slick-slider {
      margin: 1.5rem 0 2.5rem 0;
    }
    .slick-list {
      padding: 0 0 0 -5rem !important;
      @media ${media.md} {
        padding: 0 0 0 2rem !important ;
      }
    }
    .slick-dots {
      /* width: 125px;
      left: calc(0% + 28%); */
      width: 100%;
      z-index: -2;
      bottom: -45px;
      li button::before {
        font-size: 10px;
      }
      .slick-active button::before {
        color: var(--cyan);
      }
    }
    @media ${media.md} {
      width: 100%;
    }
  }
`
const SingleSlide = styled.div`
  position: relative;
  cursor: pointer;
  height: 20rem;
  width: 1rem;
  @media ${media.lm} {
    height: 25rem;
  }
  @media ${media.sm} {
    height: 30rem;
  }
  @media ${media.xl} {
    height: 40rem;
  }

  .slick-slide {
    margin-left: 5px !important;
  }
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  .mobile-image {
    @media ${media.md} {
      display: none;
    }
  }
  .desktop-image {
    display: none;
    @media ${media.md} {
      display: block;
    }
  }

  //* Slider info box
  .slider__info {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    h6 {
      display: none;
      @media ${media.md} {
        display: block;
        color: var(--cyan);
        font-weight: 900;
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
    }
    section {
      box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
      border-radius: 1.5px;
      background-color: #fff;
      width: 80%;
      margin-left: 20px;
      padding: 0.75rem;
      text-align: left;
      p {
        line-height: 1.5;
        font-size: 0.95rem;
      }
      @media ${media.lm} {
        margin-left: 25px;
      }
      @media ${media.md} {
        width: 20rem;
        box-shadow: none;
        margin: 0 0 0 4rem;
        padding: 1.5rem;
      }
      @media ${media.slg} {
        margin-left: 8.5rem;
      }
      @media ${media.lg} {
        h6 {
          color: var(--cyan);
          font-weight: 700;
          text-transform: uppercase;
          font-size: 1rem;
        }
        p {
          width: 100%;
          margin-top: 0.5rem;
          font-size: 1.2rem;
        }
      }
      @media ${media.xl} {
        margin-left: 15rem;
        padding: 2rem;
      }
    }
    @media ${media.lm} {
      bottom: 15px;
    }
    @media ${media.md} {
      bottom: 0;
    }
  }
`
const ButtonTogglesStyles = styled.section`
  margin-top: -2rem;
  .button-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.25rem;
    button {
      background: none;
      border: none;
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-color: var(--cyan);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 22px;
        height: 22px;
        fill: #fff;
        &:hover {
          fill: var(--black);
        }
      }
      &:nth-child(1) {
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }
  @media ${media.sm} {
    bottom: 45%;
  }
  @media ${media.slg} {
    width: 85px;
    height: 85px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--white);
    bottom: 45%;
    right: 22.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-top: 0.25rem;
      button {
        background: none;
        border: none;
        cursor: pointer;
        svg {
          width: 25px;
          height: 25px;
          fill: #afafaf;
          &:hover {
            fill: var(--black);
          }
        }
        &:nth-child(1) {
          svg {
            transform: scaleX(-1);
          }
        }
      }
    }
  }
`
const ButtonToggles = ({ next, prev }) => (
  <ButtonTogglesStyles>
    <div className="button-toggle">
      <button type="button" onClick={prev}>
        <SliderIcon />
      </button>
      <button type="button" onClick={next}>
        <SliderIcon />
      </button>
    </div>
  </ButtonTogglesStyles>
)

const HomeSliderNew = ({ data }) => {
  const sliderRef = useRef()
  const next = () => {
    sliderRef.current.slickNext()
  }
  const prev = () => {
    sliderRef.current.slickPrev()
  }
  const settings = {
    infinite: true,
    centerPadding: '5%',
    slidesToShow: 1.05,
    centerMode: true,
    dots: true,
    draggable: false,
  }
  return (
    <HomeSliderStyles>
      <div className="slider">
        <h3 className="d-nd">Latest News</h3>
        <Slider {...settings} ref={sliderRef}>
          {data.map((single, i) => (
            <SingleSlide
              key={i}
              onClick={() => navigate(`/latest-news/#${single.node.href}`)}
            >
              {/* <div className="bkg" /> */}
              <div className="img mobile-image">
                <GetImage
                  data={single.node.mobimage.src}
                  alt={single.node.mobimage.alt}
                  className="bkg"
                />
              </div>
              <div className="img desktop-image">
                <GetImage
                  data={single.node.img.src}
                  alt={single.node.img.alt}
                  className="bkg"
                />
              </div>
              <div className="slider__info">
                <section>
                  <h6>Our latest</h6>
                  <p>{single.node.text}</p>
                </section>
              </div>
            </SingleSlide>
          ))}
        </Slider>
        <ButtonToggles next={next} prev={prev} />
      </div>
    </HomeSliderStyles>
  )
}

export default HomeSliderNew
