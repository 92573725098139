import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const HomeRecentStyles = styled.section`
  width: 100%;
  padding: 1.5rem 0 2rem 0;
  .recent {
    width: 85%;
    margin: var(--auto);
    max-width: var(--maxWidth);
    display: flex;
    align-items: center;

    h4 {
      color: var(--cyan);
      text-transform: uppercase;
      font-weight: 300;
      font-family: var(--PoppinsBlack);
      font-size: 1.4rem;
      display: flex;
      align-items: center;
    }
    .gatsby-image-wrapper {
      cursor: pointer;
      margin-left: 1rem;
      @media ${media.md} {
        margin-left: 1rem;
      }
      img {
        object-fit: contain !important;
      }
    }
    @media ${media.md} {
      width: 100;
      display: flex;
      align-items: center;
      h4 {
        margin-bottom: 0;
        padding-left: 4rem;
      }
    }
  }
`
const RecentWorkPopUp = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popbkg {
    background-color: rgba(0, 228, 225, 0.25);
    backdrop-filter: saturate(100%) blur(10px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    cursor: pointer;
  }
  .popup {
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    width: 90%;
    margin: var(--auto);
    iframe {
      width: 100%;
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    div {
      width: 40%;
      height: 2px;
      background-color: var(--cyan);
      content: '';
      transform-origin: 8.5px;
      &:nth-child(1) {
        transform: rotate(50deg);
      }
      &:nth-child(2) {
        transform: rotate(-50deg);
      }
    }
  }
`

const HomeRecent = () => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else if (!open) {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  return (
    <HomeRecentStyles>
      <div className="recent" onClick={() => setOpen(true)}>
        <h4 className="title-main">
          Our
          <br /> Recent
          <br /> Work
        </h4>
        <StaticImage src="../../images/btn-play.png" alt="" />
      </div>
      <RecentPopUp open={open} setOpen={setOpen} />
    </HomeRecentStyles>
  )
}

export default HomeRecent

const RecentPopUp = ({ open, setOpen }) =>
  open ? (
    <RecentWorkPopUp>
      <div className="popbkg" onClick={() => setOpen(false)} />
      <div className="popup">
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/644734219?h=e3818986be"
          width="640"
          height="360"
          frameBorder="0"
          allowFullScreen
        />
      </div>
      <button type="button" className="close" onClick={() => setOpen(false)}>
        <div />
        <div />
      </button>
    </RecentWorkPopUp>
  ) : (
    ''
  )
