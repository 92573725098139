import PropTypes from 'prop-types'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const GetImage = ({ data, alt }) => {
  const image = getImage(data)
  return <GatsbyImage image={image} alt={alt} />
}

export default GetImage
