import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import DesktopVideo from 'images/videos/home-desktop.mp4'
import MobileVideo from 'images/videos/home-mobile.mp4'
import { media } from 'utils/Media'
import { StaticImage } from 'gatsby-plugin-image'

const MainHeaderStyles = styled.header`
  width: 100%;
  height: 100vh;
  /* padding-top: 5rem; */
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  position: relative;
  .main-header {
    width: 85%;
    margin: var(--auto);
    padding-bottom: 2rem;
    h1 {
      color: var(--white);
      font-family: var(--PoppinsBlack);
      font-weight: 900;
      position: relative;
      text-shadow: 0px 4px 22px #000;
      text-align: center;
      > span {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        .dot {
          color: var(--cyan);
          font-size: 3rem;
          vertical-align: text-bottom;
          position: absolute;
          bottom: 0px;
        }
      }
    }
    .header-arrow {
      display: none;
    }
  }
  .video {
    overflow: hidden;
    display: block;
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -99;
      background-size: cover;
    }
    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: var(--footer);
      opacity: 0.45;
    }
  }
  .desktop {
    display: none;
  }
  @media ${media.s} {
    height: 100vh;
    .main-header {
      h1 {
        padding-bottom: 3.5rem;
      }
    }
  }
  @media ${media.sm} {
    padding-top: 0;
    align-items: center;
    height: 98vh;
    margin-top: 2rem;
    .main-header {
      width: 100%;
      text-align: center;
      h1 {
        width: 100%;
        margin: 0;
        line-height: 1.25;
        padding-bottom: 0.5rem;
        span {
          display: inline-block;
          @media ${media.md} {
            font-size: 4.5rem !important;
          }
          .dot {
            font-size: 5.5rem;
            bottom: -5px;
          }
        }
      }
      p {
        margin-top: 0.5rem;
        color: var(--white);
        font-size: 1.25rem;
      }
      .header-arrow {
        display: block;
        position: absolute;
        bottom: 1rem;
        left: 0;
        width: 100%;
        svg {
          fill: var(--white);
          height: 100px;
          width: auto;
        }
      }
    }
  }
  @media ${media.md} {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  @media ${media.xl} {
    height: 42rem;
    padding: 0;
    margin: 0 auto 0 auto;
    max-width: var(--maxWidth);
    .main-header {
      h1 {
        font-size: 4.5rem !important;
        span {
        }
      }
      p {
        font-size: 1.2rem;
        margin-top: 0;
      }
    }
  }
`
const MainHeader = ({ children, alt }) => {
  const videoRef = useRef()

  const [video, setVideo] = useState(true)
  // useEffect(() => {
  //   videoRef.current.play()
  //   setTimeout(() => {
  //     if (videoRef.current.paused) {
  //       setVideo(false)
  //     } else {
  //       setVideo(true)
  //     }
  //   }, 1000)
  // })

  return (
    <MainHeaderStyles className={alt ? 'h-alt' : ''}>
      <div className="main-header">{children}</div>
      <div className="video">
        <video
          autoPlay
          playsInline
          muted
          loop
          ref={videoRef}
          className="desktop"
        >
          <source src={DesktopVideo} type="video/mp4" />
        </video>
        <video
          autoPlay
          playsInline
          muted
          loop
          ref={videoRef}
          className="mobile"
        >
          <source src={MobileVideo} type="video/mp4" />
        </video>
      </div>
    </MainHeaderStyles>
  )
}

export default MainHeader
